<template>
  <OLoading
    class="d-loading"
    :active="active"
    :full-page="isFullPage"
  >
    <DLoadingIcon
      class="d-loading_icon"
      :is-large="isFullPage"
    />
  </OLoading>
</template>

<script>
import DLoadingIcon from '~/components/atoms/DS2/DLoadingIcon'

/** @type {import('vue').ComponentOptions} */
const dLoading = {
  name: 'DLoading',
  components: {
    DLoadingIcon
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    isFullPage: {
      type: Boolean,
      default: true
    }
  }
}
export default dLoading
</script>

<style lang="scss" scoped>
$loading-background: rgba($ds2-color-white, 0.5);

@mixin overlay($offset: 0) {
  position: absolute;
  top: $offset;
  right: $offset;
  bottom: $offset;
  left: $offset;
}

.d-loading {
  z-index: z(loader);
  &_icon {
    z-index: z(loader) + 1;
  }

  &::v-deep {
    &.is-full-page {
      position: fixed;
    }
    .loading-overlay {
      @include overlay;

      z-index: z(loader);
      display: flex;
      overflow: hidden;

      align-items: center;
      justify-content: center;
      &.is-active {
        display: flex;
      }
    }
    .loading-background {
      @include overlay;

      background: $loading-background;
    }
  }
}
</style>
