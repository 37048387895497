<template>
  <div
    class="c-headerDropdown"
    :class="{
      'c-headerDropdown-visible': isVisibleContent,
      'c-headerDropdown-noTriangle': noTriangle
    }"
    @mouseleave="isVisibleContent = false"
  >
    <div
      class="c-headerDropdown_trigger"
      @mouseenter="isVisibleContent = true"
      @touchstart="touchTrigger"
    >
      <slot name="trigger" />
    </div>
    <div class="c-headerDropdown_menu">
      <div
        class="c-headerDropdown_content"
        :style="{ 'max-height': maxContentHeight }"
        @mouseleave="$emit('mouseleave-content')"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/** @type {import('vue').ComponentOptions} */
const headerDropdown = {
  name: 'HeaderDropdown',
  data() {
    return {
      maxContentHeight: '100vh',
      isVisibleContent: false
    }
  },
  mounted() {
    const { top, height } = this.$el.getBoundingClientRect()
    this.maxContentHeight = `${window.innerHeight - top - height - 8}px`
  },
  methods: {
    touchTrigger() {
      this.isVisibleContent = !this.isVisibleContent
      this.$emit('touch-trigger')
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.isVisibleContent = false
        this.$emit('mouseleave-content')
      }
    }
  },
  props: {
    noTriangle: {
      type: Boolean,
      default: false
    }
  }
}
export default headerDropdown
</script>

<style lang="scss" scoped>
.c-headerDropdown {
  position: relative;
  &_trigger {
    position: relative;
    &::after {
      @include arrow(8px);

      position: absolute;
      bottom: -8px;
      left: calc(50% - 4px);
      z-index: z(dropdown, arrow);
      display: none;
    }
  }
  &_menu {
    position: absolute;
    top: 100%;
    left: -8px;
    display: none;
    padding-top: 4px;
    min-width: 12rem;
  }
  &_content {
    z-index: z(dropdown, content);
    overflow-y: auto;
    padding: 0.5em 0;
    border: solid 1px $ds2-color-gray-50;
    border-radius: 4px;
    background-color: $ds2-color-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    ::v-deep .dropdown-item {
      color: $ds2-color-gray-900;
      font-size: 13px;

      &.-title {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  &-visible {
    > .c-headerDropdown {
      &_trigger {
        &::after {
          display: block;
        }
      }
      &_menu {
        display: block;
      }
    }
  }
  &-noTriangle {
    > .c-headerDropdown {
      &_trigger {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
