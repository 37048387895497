<template>
  <div
    class="c-container"
    @mousemove="hasActivatedDropdown = true"
  >
    <HeaderDropdown
      ref="dropdown"
      @touchstart.native="triggerHref = 'javascript:;'"
      @mouseleave-content="initCurrentCategory"
      @touch-trigger="hasActivatedDropdown = true"
    >
      <!-- // NOTE: NuxtLinkだとhrefに 「/javascript:;」 と設定されてしまうのでaタグに変更 -->
      <a
        :href="triggerHref"
        class="c-trigger"
        slot="trigger"
      >
        <CoconalaIcon
          v-if="isBusinessHeader"
          color="ds2Gray700"
          name="list"
          size="12px"
        />
        {{ $t('header.searchMenuCategory') }}
        <CoconalaIcon
          name="chevron-down"
          color="ds2Gray600"
          size="8px"
        />
      </a>
      <ClientOnly>
        <div
          v-if="!hasMountedDropdown"
          class="c-loading"
        >
          <DLoading
            :is-full-page="false"
            active
          />
        </div>
        <SearchMenuCategoryLv1
          v-if="hasActivatedDropdown"
          :categories="displayCategories"
          :is-business-before-login="headerType === HEADER_TYPE.BUSINESS_BEFORE_LOGIN"
          :header-type="headerType"
          @change-category="changeCurrentCategory($event)"
          @mounted="hasMountedDropdown = true"
          v-intersect-translate.once
        />
        <SearchMenuCategoryLv2
          v-for="category in displayCategories"
          v-show="currentCategoryId === category.id"
          :category="category"
          :is-business-before-login="headerType === HEADER_TYPE.BUSINESS_BEFORE_LOGIN"
          :key="`searchMenuCategoryLv2-${category.id}`"
          @change-sub-category="changeCurrentSubCategory($event)"
          v-intersect-translate.once
        />
        <SearchMenuCategoryLv3
          v-if="displayCategoryTypes.length"
          :category-types="displayCategoryTypes"
          :sub-category-id="currentSubCategoryId"
          :sub-category-name="currentSubCategoryName"
          :sub-category-service-kind="currentSubCategoryServiceKind"
          :is-business-before-login="headerType === HEADER_TYPE.BUSINESS_BEFORE_LOGIN"
        />
        <SearchMenuCategoryLv2UseScenes
          v-if="!isBusinessHeader"
          v-show="currentCategoryId === 'useScenes'"
          v-intersect-translate.once
        />
      </ClientOnly>
    </HeaderDropdown>
  </div>
</template>
<script>
import DLoading from '~/components/atoms/DS2/DLoading'
import { defineComponent, useContext, computed } from '@nuxtjs/composition-api'
import { SERVICE_KIND_VALUE } from '~/constants/search'
import HeaderDropdown from './HeaderDropdown'

import { mapState, mapGetters } from 'vuex'

const MASTER_CATEGORY_PRO_CONSULTING = 761

export default defineComponent({
  name: 'SearchMenuCategory',
  components: {
    DLoading,
    HeaderDropdown,
    SearchMenuCategoryLv1: () => import('./SearchMenuCategoryLv1'),
    SearchMenuCategoryLv2: () => import('./SearchMenuCategoryLv2'),
    SearchMenuCategoryLv2UseScenes: () => import('./SearchMenuCategoryLv2UseScenes'),
    SearchMenuCategoryLv3: () => import('./SearchMenuCategoryLv3')
  },
  props: {
    categories: {
      type: Array,
      default() {
        return []
      }
    },
    initialCategoryId: {
      type: Number,
      default: null
    },
    initialSubCategoryId: {
      type: Number,
      default: null
    },
    headerType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentCategoryId: this.initialCategoryId,
      currentSubCategoryId: this.initialSubCategoryId,
      currentSubCategoryName: '',
      currentSubCategoryServiceKind: '',
      hasActivatedDropdown: false,
      hasMountedDropdown: false,
      $dropdownContent: null,
      triggerHref: '/search'
    }
  },
  computed: {
    ...mapState('constants', ['HEADER_TYPE']),
    ...mapState('master', [
      'categoryDivinationId',
      'disallowVideoCategoryIds',
      'agentAndLegalCategoryId'
    ]),
    ...mapState('my/header', ['privateAccountInfo']),
    ...mapGetters('master', ['flattenMasterCategories', 'businessMasterCategories']),
    ...mapGetters('master', ['getRelatedCategories']),
    displayCategories() {
      if (!this.hasMountedDropdown) {
        return []
      }
      const useCategories =
        this.isBusinessHeader || this.isMzhcHeader ? this.businessMasterCategories : this.categories
      const categories = useCategories.map(c => {
        const category = this.$util.clone(c)

        /* 各大カテ内に子カテ追加 */
        const relatedCategories = this.getRelatedCategories(category.id)
        relatedCategories.forEach(c => {
          category.subCategories.push(c)
        })

        if (category.id === this.agentAndLegalCategoryId && !this.privateAccountInfo.isMzhc) {
          category.subCategories.push({
            name: '弁護士相談・法律相談',
            isLegal: true
          })
        }

        if (category.id === this.categoryDivinationId) {
          category.subCategories.unshift(
            {
              id: category.id,
              name: 'メッセージ・チャット占い',
              isText: true
            },
            {
              id: category.id,
              name: '電話占い',
              isPhone: true
            }
          )
        }

        return category
      })

      const subCategories =
        this.isBusinessHeader || this.isMzhcHeader
          ? this.flattenMasterCategories.filter(c => c.isPro && c.businessFlag)
          : this.flattenMasterCategories.filter(c => c.isPro)

      // 「PRO認定」を大カテゴリとして追加
      // NOTE: SearchMenuCategoryLv1.vue 内でもカテゴリ一覧に「人気のご利用シーン」を追加している為、いじる時は要確認
      categories.unshift({
        id: -2, // 子カテリスト表示ロジックがバグらないように仮の値を付与
        name: 'PRO認定',
        relativePath: '/search?pro_priority_display=1&ref=header',
        subCategories
      })

      return categories
    },
    displayCategoryTypes() {
      if (!this.currentSubCategoryId) {
        return []
      }

      const masterCategoryTypesList = this.$util.clone(this.masterCategoryTypesList)
      const categoryTypes =
        masterCategoryTypesList.find(t => {
          if (t.masterCategoryId === this.categoryDivinationId) {
            return this.currentSubCategoryServiceKind
          }
          return t.masterCategoryId === this.currentSubCategoryId
        })?.masterCategoryTypesList || []

      // カテゴリタイプのミラーカテゴリ追加
      const relatedCategories = this.getRelatedCategories(this.currentSubCategoryId)
      relatedCategories.forEach(c => categoryTypes.push(c))

      // 士業コンサルの場合は末尾に法律相談のリンクを追加
      if (
        this.currentSubCategoryId === MASTER_CATEGORY_PRO_CONSULTING &&
        !this.privateAccountInfo.isMzhc
      ) {
        categoryTypes.push({
          name: '弁護士相談・法律相談',
          isLegal: true
        })
      }

      return categoryTypes
    },
    isBusinessHeader() {
      return (
        this.headerType === this.HEADER_TYPE.BUSINESS ||
        this.headerType === this.HEADER_TYPE.BUSINESS_BEFORE_LOGIN
      )
    },
    isMzhcHeader() {
      return this.headerType === this.HEADER_TYPE.MZHC
    }
  },
  created() {
    if (this.headerType === this.HEADER_TYPE.BUSINESS_BEFORE_LOGIN) {
      this.triggerHref = '/search?is_business=1'
    }
  },
  methods: {
    initCurrentCategory() {
      this.currentCategoryId = null
      this.currentSubCategoryId = null
      this.currentSubCategoryName = ''
    },
    changeCurrentCategory(categoryId) {
      this.currentCategoryId = categoryId
      this.currentSubCategoryId = null
      this.currentSubCategoryName = ''
    },
    changeCurrentSubCategory(subCategory) {
      this.currentSubCategoryId = subCategory.id
      this.currentSubCategoryName = subCategory.name
      if (subCategory.isText) {
        this.currentSubCategoryServiceKind = SERVICE_KIND_VALUE.TEXT
      } else if (subCategory.isPhone) {
        this.currentSubCategoryServiceKind = SERVICE_KIND_VALUE.PHONE
      } else {
        this.currentSubCategoryServiceKind = ''
      }
    }
  },
  setup() {
    const { store } = useContext()

    const categoryDivinationId = store.state.master.categoryDivinationId

    const masterCategoryTypesList = computed(() => {
      const divinationCategory = store.state.master.masterCategories.find(
        category => category.id === categoryDivinationId
      )

      return [
        ...store.state.master.masterCategoryTypesList,
        {
          masterCategoryId: categoryDivinationId,
          masterCategoryTypesList: [...divinationCategory.subCategories]
        }
      ]
    })

    return {
      masterCategoryTypesList
    }
  }
})
</script>
<style lang="scss" scoped>
::v-deep .c-headerDropdown {
  &_content {
    display: flex;
    overflow-y: auto;
  }
  &-item,
  .has-link {
    &.-title {
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: 16px;
    }
  }
}
.c-trigger {
  @include link-effect-background(4px);

  display: inline-block;
  color: $ds2-color-gray-900;
  letter-spacing: 0.02em;
  font-size: 13px;

  .-list {
    vertical-align: -1px;
  }
}
.c-loading {
  min-height: 120px;
  width: 100%;
  background-color: $ds2-color-white;
}
</style>
